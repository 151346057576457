@use "src/styles/mixin";
@use "src/styles/colors";
@use "src/styles/typography";
@use "src/styles/variables";
.mention-dropdown-container {
  position: relative;
  width: 100%;

  .auto-complete-container {
    position: relative;
    width: 100%;

    .mention-auto-complete-dropdown {
      max-height: 259px;
      border: 1px solid colors.$grey10;
      top: 0 !important;

      .rc-virtual-list-scrollbar {
        display: block !important;
      }

      .ant-select-item-option-active {
        background: colors.$grey5;
      }

      .ant-select-item {
        height: unset;
      }

      .user-container {
        display: flex;
        gap: 12px;
        cursor: pointer;

        .text-container {
          @include mixin.flexible(column);

          .full-name {
            @include typography.sub-heading-large;
            color: colors.$darkGrey;
          }

          .username {
            @include typography.body-small;
            color: colors.$grey50;
          }

          .shop-name {
            @include typography.DM-Sans-italic-font;
            @include typography.medium;
            color: colors.$grey50;
          }
        }
      }
    }
  }
}