@use "src/styles/mixin";
@use "src/styles/typography";
@use "src/styles/variables";
@use "src/styles/colors";
.mojo-skeleton {
  @include mixin.size(100%);
  @include mixin.flexible(row, space-between, flex-end);
  background: colors.$grey85;
  padding: 28px;

  .title-skeleton {
    @include mixin.flexible(row, flex-start, center);

    .ant-skeleton-title {
      background: colors.$grey50;
      width: 100px;
    }

    li {
      background: colors.$grey50;
    }

    .ant-skeleton-avatar {
      background: colors.$grey50;
    }

    .ant-skeleton-content {
      .ant-skeleton-paragraph {
        width: 177px;
        margin-top: 0;
      }
    }
  }

  .image-skeleton {
    @include mixin.size(89px, 99px);
    background: colors.$grey50;
    border-radius: 8px;

    .ant-skeleton-header {
      padding: 0;

      .ant-skeleton-avatar {
        @include mixin.size(100%);
      }
    }
  }

  .text-skeleton {
    .ant-skeleton-paragraph {
      margin-bottom: 0;


      li {
        background: colors.$grey50;
        height: 12px;
      }
    }
  }
}