@use "src/styles/mixin";
@use "src/styles/typography";
@use "src/styles/variables";
@use "src/styles/colors";
.comment-wrapper-container {
  @include mixin.flexible(column);

  .decorative-input-container {
    .avatar-container {
      margin-right: 8px;
    }
  }

  .replies-container {
    padding-left: 40px;

    .reply-text {
      @include mixin.flexible(row, flex-start, center);
      @include typography.button-small;
      width: fit-content;
      gap: 8px;
      color: colors.$grey50;
      cursor: pointer;
      margin-top: 6px;
      margin-bottom: 12px;

      .line {
        @include mixin.size(22px, 0);
        border: 1px solid colors.$grey25;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }
}