@use "src/styles/mixin";
@use "src/styles/colors";
@use "src/styles/typography";
@use "src/styles/variables";
.menu-search .search-container-menu {
  .ant-select-selection-search, input, .ant-select-selection-search, .base-input {
    @include mixin.size(100%)
  }

  input {
    border-radius: 20px;
  }
}