@use "src/styles/colors";
@use "src/styles/typography";

.expanded-text-container {
  @extend %body-regular;
  color: colors.$grey85;
  width: 100%;

  .link {
    color: colors.$indigo;
  }

  .clickable-text {
    cursor: pointer;
    color: colors.$grey50;

    &:hover {
      text-decoration: underline;
    }
  }

  &.hide-button {
    .clickable-text {
      display: none;
    }
  }
}