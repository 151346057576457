@use "src/styles/mixin";
@use "src/styles/colors";
@use "src/styles/typography";
@use "src/styles/variables";
.profile-card-container {
  @include mixin.size(variables.$max, variables.$fit);
  background: white;
  border-radius: 4px;

  .header {
    @include mixin.flexible(column, flex-start, flex-start);
    margin-bottom: 4px;

    .cover-photo-container {
      @include mixin.size(variables.$max, 88px);

      .cover-photo {
        @include mixin.size(100%);

        img, .overlay {
          border-radius: 4px 4px 0 0;
          object-fit: cover;
        }
      }
    }

    .avatar-container {
      margin-left: 16px;
      margin-top: -27px;

      .avatar {
        border: 4px solid white;
        cursor: pointer;
      }
    }

  }
  
  .text-container {
    @include mixin.flexible(column, flex-start, flex-start);
    padding: 0 16px;
    margin-bottom: 16px;
    
    .clickable {
      cursor: pointer;
      
      &:hover {
        text-decoration: underline;
      }
    }

    .badges-container{
      @include mixin.flexible(column, flex-start, flex-end);
      gap:8px;
      margin-top: -22px;
    }

    .text-badges-container {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    .shop-name {
      @include typography.heading-5;
      @include mixin.ellipsisLines(1, 1.5rem);
      color: colors.$darkGrey;
    }

    .username {
      @include typography.body-regular;
      @include mixin.ellipsisLines(1, 1.5rem);
      text-align: center;
      color: colors.$grey50;
      margin-bottom: 4px;

      &:before {
        content: "@";
      }
    }

    .followers-container {
      display: flex;
      gap: 16px;

      .follow-text-container {
        @include typography.button-medium-large;
        color: colors.$darkGrey;
      }

      > span {
        display: flex;

        .follow {
          @include typography.body-regular;
          color: colors.$grey50;

          &:before {
            content: "\00a0 ";
          }
        }
      }
    }
  }

  .button-container {
    padding: 0 16px 12px;
  }

  .profile-card-footer {
    @include mixin.flexible(row, center, center);
    border-top: 1px solid colors.$grey10;

    .button {
      @include mixin.size(100%);
      padding-bottom: 14px;
      padding-top: 16px;
    }
  }
}