@use "src/styles/mixin";
@use "src/styles/colors";
@use "src/styles/typography";
@use "src/styles/variables";
.post-container {
  @include mixin.size(variables.$max, variables.$max);
  padding: 4px 0 12px;
  background: white;
  margin-bottom: 12px;
  scroll-behavior: smooth;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  box-shadow: 0 1px 7px rgba(40, 47, 45, 0.07);
  border-radius: 4px;
  transition: all 0.2s ease-in-out;

  &.hasShadow{
    box-shadow: unset;
  }

  &.specific-post {
    margin-bottom: 0;

    .user-details-container {
      .text-container {
        .title-container {
          @include typography.heading-5;
          font-size: 0.875rem;

          .follow-button {
            @include typography.button-small;
          }
        }
      }
    }
  }

  &.clickable:hover {
    cursor: pointer;
    box-shadow: 0 4px 28px rgba(25, 30, 28, 0.08);
  }

  .ant-dropdown-menu {
    width: 172px;
    border-radius: 4px;
    padding: 4px;

    .ant-dropdown-menu-item {
      min-width: unset;
      border-radius: 4px;
    }
  }

  .tag-container {
    padding: 12px;
  }

  .expanded-text-container {
    @extend %body-small;
    color: colors.$darkGrey;
    padding: 0 12px;
    margin-bottom: 24px;

    &.no-text {
      margin: 0;
    }

    .clickable-text {
      color: colors.$grey;
    }

    a:hover {
      text-decoration: underline;
    }
  }

  .repost-wrapper {
    padding: 0 12px;
  }

  .background-index-container {
    @include mixin.size(100%);
    @include mixin.flexible(row, center, center);
    position: relative;
    cursor: auto;
    padding: 0 60px;
    aspect-ratio: 1.5;

    .image-wrapper {
      position: absolute;
      height: unset;

      img {
        max-height: 498px;
        aspect-ratio: 1.5;
      }
    }

    .text {
      @include typography.heading-2;
      text-align: center;
      word-break: break-word;
      z-index: 1;

      .link {
        color: colors.$indigo;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .images-grid-container {
    width: 100%;
    height: 400px;
  }

  > .expanded-text-container {
    padding-bottom: 12px;
    @extend %body-small;

    .text {
      color: black;
    }
  }

  .post-details-inner-container {
    @include mixin.flexible(column);
    flex: 1;
    width: 100%;
    padding: 0;

    &.no-images {
      .reaction-container {
        margin-top: 0;
      }
    }

    .post-image-container {
      width: 100%;

      .mojo-skeleton {
        height: 736px;
      }

      .mojo-container {
        .text-overlay {
          background: none;
        }

        .action-buttons-container {
          .likes-container {
            display: none;
          }

          .clickable-image {
            display: none;
          }
        }

        video {
          object-fit: contain;
          max-height: 736px;
        }
      }

      .product-feed-container {
        margin: 0;

        .product-image {
          height: 413px;
        }
      }
    }

    > .expanded-text-container .text {
      white-space: pre-wrap;
      overflow-wrap: anywhere;
      width: 100%;
    }
  }

  .post-footer-container {
    .reaction-container {
      margin-top: 16px;
      padding: 0 16px 12px;
    }

    .post-actions-menu-container {
      margin-bottom: 16px;
    }
  }

  .comments-container {
    margin-bottom: 16px;

    &.no-comments {
      margin-bottom: 0;
    }
  }

  .input-wrapper {
    padding: 0 8px;

    .decorative-input-container {
      margin: 0;
    }
  }

  .empty-state {
    justify-content: center;
    flex: 1;
  }
}

@media only screen and (max-width: variables.$mobile) {
  .post-container {
    margin-bottom: 8px;
    border-radius: 0;

    .images-grid-container {
      height: 300px;
    }

    .background-index-container {
      padding: 0;
    }

    .post-actions-menu-container {
      padding: 0 12px;

      .decorative-image {
        padding: 8px 4px;
      }
    }
  }
}