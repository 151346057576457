@use "src/styles/mixin";
@use "src/styles/typography";
@use "src/styles/colors";
@use "src/styles/variables";

.follow-shops-card-container {
  display: flex;
  flex-direction: column;
  gap: 1px;
  border-radius: 4px;
  box-shadow: 0 1px 15px rgba(40, 47, 45, 0.1);
  cursor: pointer;
  position: relative;
  border: 1px solid white;

  .images-container {
    border-radius: 4px;
    display: flex;
    gap: 1px;
    height: 97px;
    position: relative;

    .premium-tag-container {
      z-index: 1;
      position: absolute;
      top:8px;
      left: 8px;
    }

    .image-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        object-fit: cover;
      }

      &.empty {
        img {
          @include mixin.size(64px);
          object-fit: contain;
        }
      }
    }




    .image-wrapper:nth-child(2) {
      img {
        border-top-left-radius: 4px;
      }
    }

    .image-wrapper:nth-child(4) {
      img {
        border-top-right-radius: 4px;
      }
    }

    .avatar-container{
      position: absolute;
      top:72px;
      left: 12px;
      .avatar {
        border: 2px solid white;
      }
    }
  }

  .content-container {
    display: flex;
    flex-direction: row;
    padding: 18px 12px;

    .text-container {
      padding: 0;

      .shop-name {
        @include typography.poppins-semi-bold;
      }

      .details {
        @include mixin.flexible(row, flex-start, center);
        color: colors.$grey50;

        .dot {
          @include mixin.size(2px);
          margin: 0 4px;
          background: colors.$grey50;
          border-radius: 100%;
        }

        .shop-category {
          @include typography.body-small;
        }

        .shop-username:before {
          content: "@";
        }
      }
    }
  }

  &.selected {
    border: 1px solid colors.$indigo;
    background-color: colors.$indigo5;
  }
}