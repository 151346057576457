@use "src/styles/mixin";
@use "src/styles/colors";
@use "src/styles/typography";
@use "src/styles/variables";
.basket-popover-container {
  .ant-popover-arrow {
    margin-right: -6px;
  }

  .basket-popover-inner-container {
    @include mixin.flexible(column);
    max-height: 462px;
    width: 350px;

    >.title {
      @include typography.heading-5;
      color: colors.$darkGrey;
      border-bottom: 1px solid colors.$grey10;
      padding: 16px 16px 12px;
    }

    .price {
      @include typography.heading-5;
      color: colors.$darkGrey;
    }

    .items-container {
      overflow: auto;
      height: 100%;
      padding: 0 16px 0;

      .item-container {
        @include mixin.flexible(row, space-between, flex-start);
        gap: 32px;
        padding: 16px 0;
        border-bottom: 1px solid colors.$grey10;
        border-top: 1px solid colors.$grey10;
        color: colors.$darkGrey;

        .left-side {
          display: flex;
          gap: 12px;

          .image-wrapper {
            @include mixin.size(80px);
            flex-shrink: 0;

            img {
              object-fit: cover;
              border-radius: 4px;
            }
          }

          .text-container {
            @include mixin.flexible(column, space-between);

            .item-title-container {
              @include mixin.flexible(column);

              .item-title {
                @include typography.button-medium-large;
                @include mixin.ellipsisLines(1, 1.5rem);
              }

              .quantity {
                @include typography.body-small;
                color: colors.$grey50;
              }
            }

            .button {
              @include typography.button-small;
              color: colors.$indigo;
              cursor: pointer;

              &:hover {
                text-decoration: underline;
              }
            }
          }
        }
      }
    }

    .empty-state {
      padding: 78px 50px 120px;
    }

    .checkout-container {
      @include mixin.flexible(column);
      @include typography.sub-heading-large;
      gap: 16px;
      padding: 20px 16px 16px;
      border-top: 1px solid colors.$grey10;
      color: colors.$grey50;

      .text-container {
        @include mixin.flexible(row, space-between);
        text-transform: uppercase;
      }
    }
  }
}