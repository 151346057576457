@use "src/styles/mixin";
@use "src/styles/colors";
@use "src/styles/typography";
@use "src/styles/variables";
.info-container {
  box-shadow: 0 0 7px 0 rgba(40, 47, 45, 0.07);

  .text {
    display: inline-block;
  }

  .item {
    margin-right: 4px;
  }

  .underline {
    cursor: pointer;
    text-decoration: underline;
  }

  .image-container {
    display: inline-block;
    vertical-align: middle;
  }
}