@use "src/styles/variables";

.user-details-badges-container {
  display: flex;
  gap: 8px;
  
  &.reverse {
    flex-direction: row-reverse;
    width: fit-content;  // Usefull when you have only one badge
  }
}