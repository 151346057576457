@use "src/styles/mixin";

@use "src/styles/colors";
@use "src/styles/typography";

.decorative-input-container {
  @include mixin.flexible(column, center, flex-start);
  gap: 12px;
  margin-top: 8px;
  width: 100%;

  &.is-reply {
    padding-left: 64px;
    margin-bottom: 24px;
  }

  .mention-dropdown-container {
    padding: 12px;

    .ant-select-selector {
      min-height: unset;
    }
  }

  .input-buttons {
    @include mixin.flexible(row, center, center);
  }

  .input-image-container {
    padding: 0 4px;
    width: 100%;
    background: colors.$grey5;
    border: colors.$grey10 1px solid;
    border-radius: 24px;

    .input-container {
      @include mixin.flexible(row, flex-start, center);
      min-height: 48px;

      .avatar-container {
        margin-top: 4px;
      }

      .textarea-container {
        @include typography.body-small;
        flex: 1;

        ::placeholder {
          color: colors.$grey50;
          opacity: unset;
        }
      }

      .camera-icon {
        @include mixin.flexible(row, center, center);
        padding: 4px;
        border-radius: 50%;
        cursor: pointer;

        path {
          fill: colors.$grey65;
        }

        svg {
          @include mixin.size(24px);
        }

        &:hover {
          background: colors.$grey10;
        }
      }

      .button {
        margin-left: 8px;
      }
    }

    &:not(:has(.image-container)) {
      border-radius: 24px;
    }

    .image-container {
      margin-top: 8px;
      padding: 4px 4px 4px 0;
      cursor: auto;

      .comment-image {
        @include mixin.flexible(row, center, center);
        @include mixin.size(80px);
        position: relative;

        .remove-button {
          @include mixin.size(18px);
          position: absolute;
          right: 8px;
          top: 8px;
          z-index: 1;
          padding: 0;

          svg {
            @include mixin.size(12px);
          }
        }

        img {
          border-radius: 19px;
        }
      }
    }
  }
}

