@use "src/styles/mixin";
@use "src/styles/colors";
@use "src/styles/typography";
@use "src/styles/variables";
.post-actions-menu-container {
  @include mixin.flexible(column, center);
  padding: 0 16px;

  .post-buttons-container {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid colors.$lightBorder;
    border-bottom: 1px solid colors.$lightBorder;
    padding: 4px 0;

    .decorative-image {
      @include mixin.flexible(row, center, center);
      position: relative;
      gap: 8px;
      padding: 12px 20px;
      cursor: pointer;
      border-radius: 4px;
      flex: 1;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;

      .text {
        @include typography.button-medium-large;
        color: colors.$grey65;
      }

      .icon {
        @include mixin.size(16px);

        path {
          fill: colors.$grey65;
        }
      }

      &:hover {
        background: colors.$grey5;
      }

      .lottie-container {
        @include mixin.size(18px);
        position: relative;

        > div {
          position: absolute;
          left: -12px;
          top: -5px;
        }
      }

      &.filled {
        .text {
          color: colors.$main;
        }
        .icon {
          path {
            fill: colors.$main;
          }
        }
      }
    }
  }
}