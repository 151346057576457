@use "src/styles/mixin";
@use "src/styles/colors";
@use "src/styles/typography";
@use "src/styles/variables";
@keyframes opacityAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.mojo-container {
  position: relative;
  width: 100%;

  > .mojo-comments-container {
    display: none;
    position: absolute;
    z-index: 999;
    bottom: 0;
    width: 100%;
  }

  .details-wrapper {
    @include mixin.size(100%);
    @include mixin.flexible(row, space-between, flex-start);
    gap: 24px;
    position: absolute;
    padding: 24px;
    left: 0;
    top: 0;


    .left-side {
      @include mixin.flexible(column, flex-end, flex-start);
      height: 100%;
    }

    .right-side {
      @include mixin.flexible(column, space-between, flex-end);
      height: 100%;
    }
  }

  .clickable-image {
    @include mixin.size(36px);
    padding: 8px;
    border-radius: 50%;
    background: rgba(25, 30, 28, 0.50);
    backdrop-filter: blur(4.5px);
    z-index: 2;

    svg {
      @include mixin.size(100%);
    }

    &.is-liked {
      svg {
        animation: opacityAnimation 0.5s ease-in-out;

        path {
          fill: colors.$main;
        }
      }
    }

    path {
      fill: white;
    }
  }

  .mojo-user-details-container {
    @include mixin.flexible(column);
    gap: 12px;
    z-index: 3;
    color: white;

    .details-container {
      @include mixin.flexible(row, flex-start, flex-start);
      gap: 12px;

      .names-container {
        @include mixin.flexible(column);
        @include typography.body-small;

        .title {
          @include typography.heading-5;
          @include mixin.ellipsisLines(1, 1.5rem);
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }

        .description {
          @include mixin.ellipsisLines(1, 1.25rem);
        }
      }

      .button {
        padding: 2px 12px;
        color: black;

        .text {
          margin: 0;
        }

        &.following {
          background: transparent;
          color: white;
          border: 1px solid white;
        }
      }
    }
  }

  .expanded-text-container {
    @include typography.body-small;
    color: white;
    max-width: 300px;

    .clickable-text {
      display: none;
    }
  }

  .action-buttons-container {
    @include mixin.flexible(column, flex-start, flex-end);
    gap: 12px;
    z-index: 2;

    .likes-container {
      @include mixin.flexible(column, flex-start, center);
      @include typography.button-medium-large;
      color: white;
    }

    .mojo-product-container {
      @include typography.button-small;
      @include mixin.flexible(column, flex-start, center);
      cursor: pointer;
      gap: 4px;
      border: 2px solid white;
      color: colors.$darkGray;
      background: white;
      border-radius: 5.4px 5.4px 4px 4px;
      margin-top: 12px;

      .image-wrapper {
        @include mixin.size(76px);

        img {
          border-radius: 5.4px 5.4px 0 0;
        }
      }
    }
  }

  video {
    @include mixin.size(100%);
  }

  .text-overlay {
    @include mixin.size(variables.$max);
    background: linear-gradient(179deg, rgba(73, 79, 77, 0.00) 75%, rgba(13, 13, 13, 0.69) 90%);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    pointer-events: none;
  }

  &:has(.expanded-text) {
    .text-overlay {
      background: getColor(colors.$darkGrey, 0.67);
    }
  }
}

.mojo-comments-popover {
  z-index: 3;

  .ant-popover-inner {
    border-radius: 16px;
  }
}

@media only screen and (max-width: 900px) {
  .mojo-container {
    > .mojo-comments-container {
      display: flex;
    }
  }
}
