@use "src/styles/mixin";
@use "src/styles/colors";
@use "src/styles/typography";
@use "src/styles/variables";
.post-header-container {
  @include mixin.flexible(row);
  justify-content: space-between;
  padding: 12px 12px 16px;
  background: white;

  .user-details-button {
    @include mixin.flexible(column, flex-start, flex-end);

    .options-menu {
      border-radius: 4px;

      &.ant-dropdown-trigger {
        @include mixin.size(24px);
        transform: rotate(90deg);
      }

      &:hover {
        background: colors.$grey5;
      }
    }
  }
}

.user-details-and-badges {
  @include mixin.flexible(row, flex-start);
  justify-content: space-between;
  width: 100%;
}

.user-details-container2 {
  @include mixin.flexible(row);

  .images-container {
    @include mixin.flexible(row, flex-start, flex-end);

    .shop-image {
      @include mixin.size(44px);

      img {
        border-radius: 4px;
      }
    }

    .avatar-container {
      .avatar {
        border: 2px solid white;
      }

      margin-left: -18px;
    }
  }

  .dot {
    @include mixin.size(2px);
    border-radius: 100%;
  }

  .text-header {
    @include mixin.flexible(row, flex-start, center);
  }

  .text-container {
    @extend %body-small;
    @include mixin.flexible(column);
    flex: 1;
    margin-left: 12px;
    margin-right: 12px;
    color: getColor(black, 0.6);
    overflow: hidden;

    .title-container {
      @include mixin.flexible(row, flex-start, center);
      @include typography.heading-5;
      text-transform: capitalize;
      width: 100%;
      gap: 4px;
      flex: 1;

      .title-text {
        @include mixin.ellipsisLines(1, 1.5rem);
        color: colors.$darkGrey;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }

        &.dissable-link{
          text-decoration: none;
          cursor: default;
        }
      }

      .dot {
        background: colors.$darkGrey;
      }

      .follow-button-container {
        width: unset;
        margin-right: 4px;

        .follow-button {
          @include typography.button-medium-large;
          padding: 0;
        }
      }
    }

    .sub-text {
      @include mixin.flexible(row, flex-start, center);
      gap: 4px;
      color: colors.$grey50;

      .dot {
        background-color: colors.$grey50;
      }
      .default-cursor {
        cursor: default;
      }
      .undelined {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    > .text {
      @extend %text-ellipsis;
      width: 100%;

    }

  }
}


@media only screen and (max-width: variables.$mobile) {
  .post-header-container {
    padding: 12px;
  }

  .user-details-and-badges {
    flex-direction: column;
    gap:8px;
  }
}