@use "src/styles/mixin";
@use "src/styles/colors";
@use "src/styles/typography";
@use "src/styles/variables";

.menu-container {
  @include mixin.flexible(row, center, center);
  background: white;
  height: variables.$menuSizeHeader;
  width: 100%;
  z-index: 2;
  border-bottom: 1px solid getColor(black, 0.08);
  overflow: hidden;

  .menu-content {
    @include mixin.flexible(row, center, center);
    max-width: 1120px;
    width: 100%;
    height: 100%;

    .basket-button-container {
      position: relative;

      .unread-container {
        position: absolute;
        top: -4px;
        right: -4px;
      }
    }

    .menu-search {
      @include mixin.flexible(row, space-between, center);
      flex: 1;

      .avatar-container {
        display: none;
      }

      .tedooo-logo {
        @include mixin.size(40px);
        @include mixin.flexible(row, center, center);
        border-radius: 4px;
        cursor: pointer;

        svg {
          @include mixin.size(28px)
        }
      }

      .menu-icon {
        @include mixin.size(26px);
        display: none;
      }

      .notifications-button {
        @include mixin.size(32px);
        position: relative;
        display: none;

        .unread-container {
          position: absolute;
          top: -4px;
          right: -4px;
        }

        svg {
          @include mixin.size(100%);
        }
      }

      .basket-button-container {
        display: none;
      }

      .discover-title {
        @include typography.heading-5;
        color: colors.$darkGrey;
        display: none;
      }
    }

    .profile-section {
      @include mixin.flexible(row, center, center);
      height: 100%;
      gap: 12px;

      .notifications-button {
        position: relative;

        .unread-container {
          position: absolute;
          top: -4px;
          right: -4px;
        }
      }

      .create-post-button {
        padding: 10px;
        border-radius: 50%;
      }

      .menu-drop-button {
        @include mixin.size(variables.$fit, 40px);
        padding: 4px 4px 4px 12px;
        border-radius: 32px;
        border: 1px solid colors.$grey10;

        &:hover {
          background: colors.$grey5;
          box-shadow: 0 4px 7px rgba(72, 78, 76, 0.07);
        }

        svg {
          @include mixin.size(18px);
        }

        .avatar-container {
          margin-left: 8px;
        }
      }
    }
  }

  &.dark-theme {
    background: getColor(#484E4C99, 1);
    box-shadow: 0 7px 7px 0 rgba(109, 109, 109, 0.04);

    .search-container-menu {
      .ant-input-affix-wrapper {
        border: 1px solid colors.$grey50;
        background: colors.$grey85;

        .ant-input-prefix {
          svg path {
            fill: white;
          }
        }

        input {
          color: white;


          &::placeholder {
            color: white;
          }
        }
      }
    }

    .menu-item {
      svg path {
        fill: colors.$grey50;
      }

      .line {
        background: none;
      }

      &.selected {
        svg path {
          fill: white;
        }

        .line {
          background: white;
        }
      }

      &:hover {
        background: colors.$grey65;
      }
    }

    .circle-button {
      background: colors.$grey85;
      border-color: colors.$grey65;

      svg path {
        fill: white;
      }

      &:hover {
        background: colors.$grey65;
      }
    }

    .profile-section {
      .menu-drop-button {
        svg path {
          fill: white;
        }

        &:hover {
          background: colors.$grey65;
        }
      }
    }
  }
}

@media only screen and (max-width: variables.$mobile) {
  .menu-container {
    .menu-content {
      flex-direction: column;
      overflow-x: hidden;
      padding: 0 8px 0 4px;


      .menu-search {
        width: 100%;
        margin-top: 8px;

        .notifications-button {
          display: initial;
        }

        .basket-button-container {
          display: initial;

          .clickable-image {
            @include mixin.size(32px);

            svg {
              @include mixin.size(24px);

              path {
                fill: colors.$grey65;
              }
            }
          }
        }

        .menu-icon {
          display: initial;
        }

        .search-container-menu {
          flex: 1;
          overflow-x: hidden;
          padding-right: 0;
          margin-right: 4px;

        }

        .notifications-button, .basket-button-container {
          margin: 0 4px 0 0;
        }

        .tedooo-logo {
          display: none;
        }

        .discover-title {
          display: unset;
        }
      }
    }
  }
}
