@use "src/styles/mixin";
@use "src/styles/typography";
@use "src/styles/colors";
@use "src/styles/variables";

.profile-picture-container {
  background-color: white;
  width: 480px;
  height: 596px;
  border-radius: 4px;
  box-shadow: 0 4px 7px rgba(72, 78, 76, 0.07);
  padding: 40px 56px;
  @include mixin.flexible(column, flex-start, center);

  .text-content {
    @include mixin.flexible(column);
    margin-bottom: 48px;
    width: 100%;

    .title {
      @include typography.heading-3;
      color: colors.$darkGrey;
      margin-bottom: 4px;
    }

    .secondary-title {
      color: colors.$grey50;
      @include typography.body-small;
    }
  }

  .profile-picture {
    margin-bottom: 48px;
    position: relative;

    .badge {
      @include mixin.flexible(row, center, center);
      @include mixin.size(48px);
      background-color: colors.$indigo;
      border-radius: 50%;

      position: absolute;
      bottom: 8px;
      right: 4px;

      &:hover {
        background: colors.$indigoDark;

        > svg path {
          fill: colors.$main5;
        }
      }

      svg {
        @include mixin.size(22px);

        path {
          fill: white
        }
      }
    }
  }

    .continue-button-profile {
      margin-bottom: 16px;
      width: 100%;
    }

    .skip-for-now-button-profile {
      width: 100%;
    }

}

@media only screen and (max-width: 900px) {
  .profile-picture-container {
    .profile-picture {
      flex: 1;
    }
  }

}


