@use "src/styles/mixin";
@use "src/styles/colors";
@use "src/styles/typography";
@use "src/styles/variables";

.sidebar-section {
  @include mixin.flexible(column);
  gap: 14px;

  .section-header {
    @include mixin.flexible(row, flex-start, center);
    gap: 8px;
    padding-top: 16px;
    padding-left: 12px;
  }

  .sub-title {
    @extend %sub-heading;
    text-transform: uppercase;
    color: colors.$grey65;
  }

  .multi-items-carousel-container {
    padding: 0;
    border: none;

    .slick-list {
      padding-left: 12px;

      .slick-track {
        padding: 4px 0;

        .slick-slide {

          > div {
            margin-right: 8px;
          }
        }
      }
    }

    .category-container {
      @include mixin.flexible(column, flex-start, center);
      height: 168px;
      min-width: 240px;
      background-color: colors.$grey5;
      padding-top: 20px;
      cursor: pointer;
      border-radius: 4px;
      overflow: hidden;
      transition: transform 0.5s;

      .title {
        @include typography.heading-5;
        text-align: center;
      }

      .image-wrapper {
        transition: transform 0.5s;
        max-width: 100%;
      }

      &:hover {
        transform: translateY(-3%);
      }
    }

    .product-container {
      @include mixin.flexible(column, flex-start, center);
      @include mixin.size(184px);
      border-radius: 4px;
      position: relative;
      cursor: pointer;
      transition: transform 0.5s;

      img {
        border-radius: 4px;
        object-fit: cover;
      }

      &:hover {
        transform: translateY(-3%);
      }
    }
  }

  .section-footer {
    border-top: 1px solid colors.$grey10;;

    .button {
      @include mixin.size(100%);
      padding-bottom: 14px;
      padding-top: 16px;
    }
  }
}