@use "src/styles/mixin";
@use "src/styles/colors";
@use "src/styles/typography";
@use "src/styles/variables";

.feed-vision-section {
  @include mixin.flexible(column);
  background: white;
  margin-top: 24px;

  .text-container {
    @include mixin.flexible(column, flex-start, center);
    padding: 16px 16px 20px;
    text-align: center;

    .title {
      @include typography.heading-5;
      color: colors.$darkGrey;
      margin-bottom: 8px;
    }

    .description {
      @include typography.body-small;
      color: colors.$grey50;
      margin-bottom: 16px;
    }
  }
}
