@use "./colors";

@mixin flexible($dir:row,$organize:unset,$alignOrganize:unset,$wrap:unset) {
  display: flex;
  flex-direction: $dir;
  justify-content: $organize;
  flex-wrap: $wrap;
  align-items: $alignOrganize;
  & {
    @content;
  }
}

@mixin text($size,$color:colors.$darkGray,$weight:unset) {
  font-size: $size;
  font-weight: $weight;
  color: $color;
  & {
    @content;
  }
}

@mixin ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  & {
    @content;
  }
}
@mixin ellipsisLines($lines, $lineHeight) {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines;
  line-clamp: $lines;
  line-height: $lineHeight;
  max-height: #{$lineHeight * $lines};
  & {
    @content;
  }
}

@mixin absPos($top:unset,$right:unset,$bottom:unset,$left:unset) {
  position: absolute;
  top: $top;
  bottom: $bottom;
  right: $right;
  left: $left;
}

@mixin size($width,$height:$width) {
  width: $width;
  height: $height;
  & {
    @content;
  }
}

@mixin image($width,$height:$width,$objectFit:contain) {
  width: $width;
  height: $height;
  object-fit: $objectFit;
  & {
    @content;
  }
}

@mixin min($width,$height:unset) {
  min-width: $width;
  min-height: $height;
  & {
    @content;
  }
}
@mixin smallScrollBarStyle {
  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    background-color: white;
  }

  &::-webkit-scrollbar-thumb {
    background: colors.$grey5;
    border-radius: 10px;

  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background: white;
  }
}

@mixin button($width,$height:$width) {
  width: $width;
  transition: all 50ms;
  height: $height;
  cursor: pointer;
  object-fit: contain;

  &:hover {
    opacity: 0.9;
  }
}

