@use "src/styles/mixin";
@use "src/styles/colors";
@use "src/styles/typography";
@use "src/styles/variables";
.page-container {
  > .content {
    .chat-fallback-container {
      padding-top: 24px;
      display: flex;
      flex-direction: row;
      gap: 24px;

      .left-section {
        flex: 0.9;
        background-color: white;
        padding: 16px;
        @include mixin.size(variables.$max);

        .left-header-skeleton {
          padding: 8px 0 8px 0;
          .ant-skeleton-content {
            .ant-skeleton-title {
              margin-top: 4px;
            }
            .ant-skeleton-paragraph {
              margin-top: 16px;
            }
          }
        }

        .skeleton-sidebar-content {
          margin-top: 12px;
          .ant-skeleton-content {
            .ant-skeleton-title {
              margin-top: 4px;
            }

            .ant-skeleton-paragraph {
              margin-top: 16px;
            }
          }
        }
      }

      .right-section {
        @include mixin.flexible(column, center, center);
        gap: 24px;
        flex: 2;
        background-color: white;

        .text-skeleton-container {
          gap: 8px;
        }

        .circle-skeleton {
          @include mixin.flexible(column, flex-end, center);
          @include mixin.size(100%, 100%);
          .ant-skeleton-header {
            padding-right: 0;
            .ant-skeleton-avatar {
              @include mixin.size(200px, 200px);
            }
          }
        }

        .text-skeleton-container {
          @include mixin.size(300px);
          @include mixin.flexible(column, flex-start, center);

          .title-skeleton {
            width: 200px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: variables.$mobile) {
  .page-container {
    > .content {
      .chat-fallback-container {
        .left-section {
          width: 100%;
          flex:1;
        }

        .right-section {
          display: none;
        }
      }
    }
  }
}