@use "src/styles/mixin";
@use "src/styles/colors";
@use "src/styles/typography";
@use "src/styles/variables";
.complete-shop-box-container {
  @include mixin.flexible(column);
  @include typography.body-regular;
  color: colors.$grey50;
  background-image: url('../../photos/draft-background.png');
  background-size: cover;
  border-radius: 8px;
  padding: 20px;
  border: 1px solid #E8EEFF;
  box-shadow: 0 1px 8px rgba(40, 47, 45, 0.07);

  &.large {
  background-image: url('../../photos/large-draft-background.png');
  }

  .progress-container {
    @include typography.sub-heading;
    @include mixin.flexible(row, space-between, center);
    gap: 16px;
    margin-bottom: 16px;

    .ant-progress-inner {
      height: 12px;
      box-shadow: 0 1px 18px 0 rgba(0, 0, 0, 0.10);

      .ant-progress-bg {
        height: 12px;
      }
    }
  }

  .title {
    @include typography.heading-5;
    color: colors.$darkGrey;
    margin-bottom: 4px;
  }

  .description {
    margin-bottom: 24px;
  }

  .later-button {
    margin-top: 16px;
  }
}