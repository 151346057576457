@use "src/styles/mixin";
@use "src/styles/colors";
@use "src/styles/typography";
@use "src/styles/variables";
.create-an-account-container {
  @include mixin.flexible(column, center, center);
  width: 480px;
  gap: 24px;
  border-radius: 4px;

  .create-an-account-top-section {
    @include mixin.flexible(column, center, center);
    background-color: white;
    filter: drop-shadow(0px 2px 7px rgba(72, 78, 76, 0.15));
    padding: 40px 48px;
    width: 100%;
    border-radius: 4px;

    .title {
      @include typography.heading-4;
      color: colors.$darkGrey;
      font-size: 20px;
      margin-bottom: 8px;
    }

    .description {
      @include typography.DM-Sans-regular;
      color: colors.$grey50;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: center;
      margin-bottom: 16px;
    }

    .divider {
      width: 100%;
      @include mixin.flexible(row, flex-start, center);
      margin: 24px 0 8px 0;

      .line {
        height: 1px;
        background: colors.$grey10;
        flex: 1;
      }

      .text {
        @extend %body-small;
        color: colors.$grey50;
        padding: 0 12px;
      }
    }

    .footer {
      width: 100%;
      .row {
        @include mixin.flexible(row, space-between, center);
        width: 100%;
        margin-bottom: 16px;

        .forget-password {
          @extend %body-small;
          color: colors.$indigo;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  .create-an-account-sub-section {
    @include mixin.flexible(column, center, center);
    background-color: white;
    filter: drop-shadow(0px 2px 7px rgba(72, 78, 76, 0.15));
    padding: 24px 56px;
    width: 100%;
    border-radius: 4px;

    .title {
        @include typography.DM-Sans-medium;
        color: colors.$darkGrey;
        font-size: 16px;
        margin-bottom: 16px;
    }
  }
}

@media only screen and (max-width: variables.$mobile) {
  .create-an-account-container {
    width: 100%;
    padding: 16px 16px 64px 16px;

    .create-an-account-top-section {
      padding: 24px;

      .title {
        @include typography.heading-5;
        color: colors.$darkGrey;
        font-size: 16px;
        margin-bottom: 8px;
      }
    }

    .create-an-account-sub-section {
      display: none;
    }
  }
}