@use "src/styles/mixin";
@use "src/styles/colors";
@use "src/styles/typography";
@use "src/styles/variables";
.comments-container {
  @include mixin.flexible(column);
  width: 100%;
  padding: 0 16px;

  &.no-comments {
    margin: 0;
  }

  .view-comments-text {
    @include typography.button-small;
    color: colors.$grey65;
    cursor: pointer;
    margin-bottom: 16px;

    &:hover {
      text-decoration: underline;
    }
  }

  .comments-sort-container {
    @include mixin.flexible(row, flex-start, center);
    @extend %body-small;
    font-weight: 600;
    color: getColor(black, 0.6);
    margin: 16px 0;

    svg {
      margin-left: 4px;
    }
  }

  .load-more {
    @extend %body-small;
    cursor: pointer;
    padding: 2px 8px;
    width: fit-content;
    border-radius: 4px;
    color: getColor(black, 0.6);
    font-weight: 500;

    &:hover {
      background: getColor(black, 0.08);
    }
  }
}

@media only screen and (max-width: variables.$mobile) {
  .comments-container {
    padding: 0 8px;
  }
}