@use "src/styles/mixin";
@use "src/styles/colors";
@use "src/styles/typography";
@use "src/styles/variables";

.non-sign-in-sidebar-container {
  @include mixin.flexible(column);
  padding: 24px 16px;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0 1px 7px 0 rgba(40, 47, 45, 0.07);
  gap:32px;

  .text-container {
    @include mixin.flexible(column);
    gap: 8px;

    .title {
      @include typography.heading-5;
      color: colors.$darkGrey;
      font-size: 16px;
    }

    .description {
      @include typography.DM-Sans-regular;
      color: colors.$grey50;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
    }
  }

  .login-buttons-container {
    @include mixin.flexible(column);
    gap: 16px;
    .google-sign-in-container{
      margin-bottom: 0;
      color: black;
    }

    .facebook-button {
      @include typography.DM-Sans-regular;
      font-size: 16px;
    }

    .create-account-button{
      color: black;
      border: 1px solid colors.$grey15;
    }
  }
}