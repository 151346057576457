@use "src/styles/mixin";
@use "src/styles/colors";
@use "src/styles/typography";
@use "src/styles/variables";
.action-header-container {
  @include mixin.flexible(row, flex-start, center);
  gap: 4px;
  width: 100%;
  padding: 12px 12px 16px;
  background: white;
  border-bottom: 1px solid colors.$lightBorder;

  svg {
    margin-top: 4px;
    @include mixin.size(18px);
  }

  .text-container {
    @include mixin.flexible(row, flex-start, center);
    @extend %body-small;
    flex: 1;

    .avatar-container {
      margin-right: 8px;
    }

    .name {
      @include typography.button-medium-large;
      color: colors.$grey50;
      white-space: pre-wrap;
    }

    .action-text {
      @include typography.body-regular;
      color: colors.$grey50;
      white-space: pre-wrap;
    }
  }
}