@use "src/styles/mixin";
@use "src/styles/typography";
@use "src/styles/colors";
@use "src/styles/variables";

.main-goal-container {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 4px 7px rgba(72, 78, 76, 0.07);
  width: 480px;
  height: 656px;
  @include mixin.flexible(column, flex-start, center);

  .text-container {
    @include mixin.flexible(column);
    padding: 40px 56px 0 56px;
    width: 100%;

    .title {
      @include typography.heading-3;
      color: colors.$darkGrey;
      margin-bottom: 4px;
    }

    .secondary-title {
      color: colors.$grey50;
      @include typography.body-small;
      margin-bottom: 24px;
    }
  }

  .cards-container {
    flex:1;
    padding: 0 56px 24px 56px;
    overflow: auto;
  }

  .buttons-container {
    padding: 0 56px 40px 56px;
    width: 100%;
    box-shadow: 0 -28px 10px white;
    z-index: 1;



    .continue-button-birthday {
      margin-bottom: 16px;
    }
  }

}

@media only screen and (max-width: 900px) {
  .main-goal-container {
    .text-container {
      @include mixin.flexible(column);
      padding: 0;
      width: 100%;

    }
    .cards-container {
      padding: 0;
      width: 100%;
      flex:1;
    }
    .buttons-container {
      padding: 0;
      width: 100%;

      .continue-button-birthday {
        margin-bottom: 16px;
      }
    }
  }
}

