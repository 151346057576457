@use "src/styles/mixin";
@use "src/styles/colors";
@use "src/styles/typography";
@use "src/styles/variables";
.ant-notification-notice.primary-success-message {
  &.toggle-favorite-message {
    background: colors.$main10;
    border: 1px solid colors.$main25;
  }
}
