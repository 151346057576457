@use "src/styles/mixin";
@use "src/styles/colors";
@use "src/styles/typography";
@use "src/styles/variables";
.mojo-comments-container {
  @include mixin.flexible(column);
  background: white;
  position: relative;
  padding: 16px 0 12px;
  min-width: 402px;
  border-radius: 16px;

  > .title {
    @include typography.heading-5;
    text-align: center;
    color: colors.$darkGrey;
    padding-bottom: 16px;
    border-bottom: 1px solid colors.$grey10;
  }

  .close-icon {
    @include mixin.size(24px);
    cursor: pointer;
    position: absolute;
    top: 16px;
    right: 16px;
  }

  .comments-container {
    height: 377px;
    padding-top: 12px;
    overflow-y: auto;

    .comment-wrapper {
      .comment-inner-container {
        padding: 8px 16px 16px 8px;
      }
    }
  }

  .empty-state {
    @include mixin.flexible(column, center, center);
    min-height: 377px;
    padding: 0;
  }

  .decorative-input-container {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 900px) {
  .mojo-comments-popover {
    display: none;
  }
}
