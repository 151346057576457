@use "src/styles/mixin";
@use "src/styles/colors";
@use "src/styles/variables";
@use "src/styles/typography";

.avatar-container {
  @include mixin.size(variables.$fit);
  @include mixin.flexible(column, center, center);
  position: relative;
  background: colors.$grey5;
  border-radius: 50%;

  .avatar {
    @include mixin.size(variables.$max);
    object-fit: cover;
    border-radius: 50%;
  }

  .icon-container {
    position: absolute;
    display: flex;

    svg {
      height: 100%;
      width: 100%;
    }
  }
}