@use "src/styles/mixin";
@use "src/styles/typography";
@use "src/styles/colors";
@use "src/styles/variables";

.one-time-payment-page {
  @include mixin.flexible(column, center, center);
  width: 100%;
  height: fit-content;
  max-width: 600px;
  position: relative;
  gap: 24px;
  padding: 24px 0;

  .error-message {
    @include typography.caption;
    color: colors.$danger;
  }

  .one-time-payment-section {
    @include mixin.flexible(column, flex-start, center);
    height: fit-content;
    width: 100%;
    background: white;
    padding: 24px;

    .price-container {
      width: 100%;

      .input-container {
        .price-input {
          font-size: 2.75rem;
          @include typography.poppins-light;
          border: none;
          color: colors.$indigo;
          display: flex;
          justify-content: center;
          position: relative;
          text-align: center;
        }
      }
    }

    .payments {
      @include mixin.flexible(column);
      gap: 16px;

      .pay-with-text {
        @include typography.sub-heading-large;
        text-transform: uppercase;
        color: colors.$grey50;
      }

    }

    .avatar-container {

      margin-top: 8px;
    }

    .title {
      @include typography.heading-5;
      margin-top: 12px;
      color: colors.$darkGrey;
    }

    .username {
      @include typography.body-regular;
      color: colors.$grey50;
      margin-top: 2px;
    }

    .input-container {
      width: 100%;
      margin-top: 24px;
    }
  }

}

@media only screen and (max-width: variables.$mobile) {
  .one-time-payment-page {
    padding: 58px 12px 24px;

    .input-container {
      margin-top: 20px;
    }

    .one-time-payment-section {
      padding: 24px 16px;
      border-radius: 8px;

      .price-container {
        padding-bottom: 4px;
        border-bottom: 1px solid colors.$grey10;

        .input-container {
          margin-top: 12px;
        }
      }

      .input-container {
        margin-top: 20px;
      }

      .avatar-container {
        margin-top: -58px;

        img {
          border: 3px solid white;
        }
      }

    }

  }
}