@use "src/styles/mixin";
@use "src/styles/colors";
@use "src/styles/typography";
@use "src/styles/variables";
.rate-container {
  @include mixin.flexible(row, flex-start, center);

  .value-container {
    margin: 0 8px;
    @include typography.button-medium-large;
    color: colors.$darkGrey;

    .value {
      color: colors.$grey85;
      height: fit-content;
    }
  }

  .dot {
    display: none;
  }

  svg {
    @include mixin.size(15px);

    path {
      fill: colors.$warning;
    }
  }

  .text {
    @extend %body-regular;
    color: colors.$grey35;
    padding-left: 8px;
  }

  &.with-reviews {
    .dot {
      @include mixin.size(3px);
      border-radius: 100%;
      display: initial;
      background: colors.$grey35;
    }
  }

  &.no-reviews {
    .dot {
      display: none;
    }

    .span-text {
      padding-left: 0
    }
  }
}