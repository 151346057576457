@use "src/styles/mixin";

@use "src/styles/colors";
@use "src/styles/typography";
@use "src/styles/variables";

.footer-container {
  @include typography.label-small;
  @include mixin.flexible(row, flex-start, center);
  color: colors.$grey50;
  margin-top: 24px;

  a {
    color: colors.$grey50;
  }

  .dot {
    @include mixin.size(2px);
    margin: 0 4px;
    background: colors.$grey50;
  }

  span {
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
