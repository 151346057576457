@use "src/styles/mixin";
@use "src/styles/colors";
@use "src/styles/typography";
@use "src/styles/variables";
@use "src/styles/functions";

.comment-reaction-container {
  @include mixin.flexible(column);
  @include typography.body-small;
  line-height: 0.7rem;
  color: functions.getColor(black, 0.6);
  width: 100%;

  .decorative-image {
    @include mixin.flexible(row, center, center);

    .image {
      margin-right: 4px;
    }

    .text {
      padding: 4px;
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
        color: colors.$blue;
      }
    }
  }

  .reply-text-container {
    @include mixin.flexible(row, flex-start, center);
    gap: 8px;

    .date {
      @include typography.body-small;
      color: colors.$grey35;
    }

    .line {
      @include mixin.size(0, 12px);
      border-right: colors.$grey35 1px solid;
    }

    .text {
      @include typography.button-small;
      cursor: pointer;
      color: colors.$grey65;

      &.not-clickable {
        pointer-events: none;
      }

      &:hover {
        text-decoration: underline;
      }

      &.liked {
        color: colors.$main;
      }
    }
  }
}