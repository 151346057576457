@use "src/styles/mixin";
@use "src/styles/typography";
@use "src/styles/colors";
@use "src/styles/variables";

.no-result-container {
  @include mixin.size(variables.$max);
  @include mixin.flexible(column, flex-start, center);
  background-color: white;
  padding-top: 104px;
  gap: 32px;

  .no-result-logo {
    margin-top: 10px;
    @include mixin.size(181px, 104px);
  }

  .text-container {
    @include mixin.flexible(column, center, center);
    gap: 8px;

    .no-result-text {
      @include typography.heading-3;
      text-align: center;
    }

    .sub-text {
      padding: 0 8px;
      @include typography.body-regular;
      text-align: center;
      color: colors.$grey50;
    }
  }
}
