@use "src/styles/mixin";
@use "src/styles/colors";
@use "src/styles/typography";
@use "src/styles/variables";
.menu-drawer-header {
  @include mixin.flexible(row, flex-start, center);
  padding-bottom: 12px;
  border-bottom: 1px solid colors.$grey5;

  .text-container {
    @include mixin.flexible(column);
    margin-left: 8px;

    .title {
      @extend %button-small;
      margin-top: 4px;
    }

    .text {
      @extend %caption;
      color: colors.$grey50;
      margin-top: 2px;
    }
  }
}