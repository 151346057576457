@use "src/styles/mixin";
@use "src/styles/colors";
@use "src/styles/variables";
@use "src/styles/typography";

.facebook-sign-in-container {
  @include mixin.size(variables.$max, variables.$fit);
  @include mixin.flexible(column, center, center);

  span {
    width: 100%;

    .facebook-button {
      @include mixin.flexible(row, center, center);
      @include mixin.size(100%, 48px);
      border: none;
      outline: none;
      background-color: #1877F2;
      cursor: pointer;
      color: white;
      border-radius: 32px;
      font-family: "typography.DM-Sans-Medium", serif;

      .facebook-icon {
        @include mixin.size(24px);
        margin-right: 8px;
      }
    }
  }

  &:hover {
    .facebook-button {

      background-color: colors.$indigoDark;
    }
  }
}


