@use "src/styles/mixin";
@use "src/styles/colors";
@use "src/styles/typography";
@use "src/styles/variables";
.profile-menu-content {
  width: 210px;
  border-radius: 12px;

  &.full-width {
    width: 309px;

    .draft-wrapper {
      padding: 12px;

      .complete-shop-box-container {
        padding: 16px;
      }
    }
  }

  .profile-drop-menu {
    @include mixin.flexible(column);
    padding: 4px;

    .header {
      @include mixin.flexible(row, flex-start, center);
      padding: 6px 12px;
      cursor: pointer;
      border-bottom: 1px solid colors.$grey5;

      &:hover {
        background: colors.$indigo5;

        .text-container {
          .text {
            color: colors.$indigo;
          }
        }
      }

      .text-container {
        @include mixin.flexible(column, center);
        margin-left: 12px;

        .title {
          @extend %body-small;
          color: colors.$darkGrey;
        }

        .text {
          @extend %caption;
          color: colors.$grey50;
          margin-top: 2px;
        }
      }
    }
  }
}