@use "src/styles/mixin";
@use "src/styles/colors";
@use "src/styles/typography";
@use "src/styles/variables";
.image-uploader-button {
  @include mixin.size(variables.$fit);
  position: relative;

  .file-input {
    @include mixin.size(0.1px);
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;

  }

  label {
    @include mixin.size(40px);
  }
}