@use "src/styles/mixin";
@use "src/styles/typography";
@use "src/styles/colors";

.follow-channel-card-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-radius: 4px;
  box-shadow: 0 1px 15px rgba(40, 47, 45, 0.1);
  cursor: pointer;
  position: relative;
  border: 1px solid white;
  padding: 12px;
  height: auto;

  .top-container {
    border-radius: 4px;
    display: flex;
    gap: 8px;
    position: relative;
    align-items: center;
    height: auto; 
    .channel-image {
      @include mixin.size(48px);
    }
  }
  
  .top-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;

    @include typography.poppins-semi-bold;
    font-weight: 600 !important;
    
    .title-text {
      color: colors.$grey50;
    }
    .subscribers-text {
      @include typography.caption;
      color: colors.$grey50;
    }
  } 

  .bottom-container {
    display: flex;
    flex-direction: space-between;
    @include typography.body-small;

    .text-container {
      padding: 0;
      color: colors.$grey50;
      display: flex;
      flex-direction: column;
      gap: 4px;
    }
  }

  &.selected {
    border: 1px solid colors.$indigo;
    background-color: colors.$indigo5;
  }
}