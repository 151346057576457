@use "src/styles/mixin";
@use "src/styles/colors";
@use "src/styles/variables";
@use "src/styles/typography";.section-titles-container {
  @include mixin.flexible(column);
  margin-bottom: 16px;

  .title {
    @include mixin.flexible(row, flex-start, center);
    padding: 2px 4px;
    border-radius: 4px;
    gap: 4px;
    width: fit-content;

    svg {
      @include mixin.size(20px);
    }

    &.clickable:hover {
      cursor: pointer;
      background: colors.$grey5;
    }
  }
}