@use "src/styles/mixin";
@use "src/styles/colors";
@use "src/styles/typography";
@use "src/styles/variables";
.sticky-get-the-app-button {
  &.button {
    &.secondary {
      @include typography.button-medium-large;
      position: absolute;
      bottom: 52px;
      right: 38px;
      color: colors.$darkGrey;
      z-index: 3;
      padding: 12px 24px;

      svg path {
        fill: colors.$darkGrey;
      }
      svg {
        height: 20px;
        width: 20px;
        object-fit: contain;
      }
    }
  }
}

@media screen and (max-width: 900px) {
  .sticky-get-the-app-button {
    display: none;
  }
}