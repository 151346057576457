@use "src/styles/mixin";
@use "src/styles/typography";
@use "src/styles/variables";
@use "src/styles/colors";
.total-saves-container {
  @include typography.body-small;
  @include mixin.flexible(row, flex-start, center);
  color: colors.$darkGrey;
  gap: 4px;
  position: absolute;
  top: 12px;
  left: 12px;
  padding: 4px 12px;
  z-index: 1;
  border-radius: 32px;
  border: 1px solid rgba(233, 234, 234, 0.50);
  background: rgba(244, 245, 245, 0.50);
  backdrop-filter: blur(15.5px);
  white-space: nowrap;

  svg {
    @include mixin.size(16px);

    path {
      fill: colors.$darkGrey;
    }
  }
}