@use "src/styles/mixin";
@use "src/styles/typography";
@use "src/styles/colors";
@use "src/styles/variables";

.interests-groups-content-container {
  flex: 1;
  flex-wrap: wrap;
  width: 100%;
  overflow: auto;
  padding: 0 56px;

  .no-result-container {
    padding-top: 40px;
    gap: 16px;

    .image-wrapper {
      @include mixin.size(128px, 74px)
    }

    .text-container {
      padding: 0;

      .no-result-text {
        @include typography.heading-5;
        font-size: 16px;
        color: colors.$darkGray;
      }

      .sub-text {
        @include typography.DM-Sans-regular;
        font-size: 14px;
        color: colors.$grey50;
      }
    }
  }

  .selected-section {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .selected-title {
      padding-top: 16px;
      @include typography.sub-heading-large;
      color: colors.$darkGray;
      text-transform: uppercase;
    }

    .selected-interests-wrapper {
      display: flex;
      flex-wrap: wrap;
      gap: 16px;
    }
  }

  .results-section {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .interests-group-container {
      display: flex;
      flex-direction: column;
      margin-top: 18px;
      gap: 16px;

      .interest-title {
        @include typography.sub-heading-large;
        color: colors.$darkGray;
      }

      .interests-group-wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
      }
    }

    .interests-group-container:last-child {
      margin-bottom: 32px;
    }
  }
}
