@use "src/styles/mixin";
@use "src/styles/colors";
@use "src/styles/typography";
@use "src/styles/variables";
.menu-item {
  @include mixin.flexible(column, center, center);
  width: 108px;
  margin-right: 24px;
  height: 100%;
  padding-top: 16px;
  cursor: pointer;
  position: relative;

  .line {
    width: 100%;
    height: 2px;
    transition: all .2s ease-in-out;
    background: white;
  }

  > svg {
    @include mixin.size(24px);
    margin-bottom: 16px;
    fill: colors.$grey85;
  }

  .unread-container {
    position: absolute;
    right: 34px;
    top: 9px;
    border: 1px solid white;
  }

  &.selected {
    .line {
      background: colors.$grey85;
    }

    .text {
      color: colors.$darkGrey;
    }

    svg path {
      fill: colors.$darkGrey
    }
  }

  &:hover {
    background: colors.$grey5;
  }
}

@media only screen and (max-width: variables.$mobile) {
  .menu-item {
    @include mixin.flexible(column-reverse, flex-end, center);
    margin-right: 8px;
    padding-bottom: 8px;
    flex: 1;
    padding-top: 0;

    .unread-container {
      right: 24px;
      top: 4px;
    }

    &:last-child {
      margin-right: 0;
    }

    > svg {
      @include mixin.size(22px);
      margin-top: 8px;
      margin-bottom: 0;
    }

    .text {
      display: none;
    }
  }
}