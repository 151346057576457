@use "src/styles/mixin";
@use "src/styles/colors";
@use "src/styles/typography";
@use "src/styles/variables";
@use "src/styles/functions";

.comment-wrapper {
  display: flex;
  width: 100%;
  padding: 6px 0;
  gap: 8px;

  .dropdown-container {
    display: none;
  }

  &:hover {
    .dropdown-container {
      display: block;
    }
  }

  .avatar-container {
    cursor: pointer;
  }

  .comment-inner-wrapper {
    @include mixin.flexible(column);
    gap: 8px;
    flex: 1;

    .comment-with-dropdown {
      gap: 12px;
      @include mixin.flexible(row, flex-start, center);

      .comment-inner-container {
        @include mixin.flexible(column);
        position: relative;
        overflow: unset;
        gap: 8px;
        background: colors.$gray95;
        padding: 8px 8px 16px;
        width: fit-content;
        border-radius: 0 8px 8px 8px;

        .tags-container {
          display: flex;
          gap: 8px;

          .tag {
            border: 1px colors.$indigo15 solid;
          }
        }

        .header {
          @include mixin.flexible(row, flex-start, center);

          .ant-dropdown-trigger {
            @include mixin.flexible(row, flex-start, center);
            @include mixin.size(20px);
            margin-left: 4px;

            svg {
              @include mixin.size(14px);
              fill: getColor(black, 0.6);
            }
          }

          .full-name {
            @include typography.button-medium-large;
            @include mixin.ellipsis;
            color: colors.$darkGrey;
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }
          }

          .username {
            @include typography.body-regular;
            color: colors.$grey65;
          }

          .dot {
            @include mixin.size(2px);
            margin: 0 4px;
            background: colors.$grey65;
            border-radius: 100%;
          }


          > .image-wrapper {
            @include mixin.size(18px);
          }
        }

        .expanded-text-container {
          @extend %body-small;
          padding: 0;
          margin: 0;

          .text {
            @include typography.body-small;
            color: colors.$grey85;
          }
        }

        .comment-likes-container {
          @include mixin.flexible(row, center, center);
          @include typography.body-small;
          @include mixin.size(43px, 20px);
          position: absolute;
          bottom: 0;
          right: 8px;
          transform: translateY(50%);
          gap: 4px;
          border-radius: 16px;
          color: colors.$grey65;
          border: 1px solid colors.$grey10;
          background: white;
          cursor: pointer;
          transition: transform 0.1s linear;

          svg {
            @include mixin.size(12px);

            path {
              fill: colors.$main;
            }
          }

          &:hover {
            transform: translateY(50%) scale(1.1);
          }
        }
      }

      .dropdown-container {
        position: relative;

        .button {
          padding: 8px;
          border-color: colors.$indigo15;
        }
      }
    }

    .comment-image-container {
      @include mixin.size(124px);
      border-radius: 8px;

      img {
        border-radius: 8px;
      }
    }
  }

  &.reply {
    .comment-inner-wrapper .comment-inner-container {
      padding: 8px;
      max-height: 160px;
    }
  }

  &.deleted .comment-inner-container .expanded-text-container .text {
    @extend %body-small;
    font-style: italic;
    font-weight: 500;
    color: colors.$grey;
  }

}

.options-menu-content {
  &.comment-drop-menu {
    width: 200px;

    .options-menu-items .item {
      svg {
        @include mixin.size(14px)
      }
    }
  }
}