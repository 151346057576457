@use "src/styles/mixin";
@use "src/styles/variables";
@use "src/styles/colors";

.shop-skeleton-container {
  max-width: variables.$maxWidth;
  width: 100%;

  .skeleton-section {
    box-shadow: 0 1px 7px #282f2d12;
    border-radius: 4px;
    background: white;

  }

  .shop-skeleton-top-container {
    padding: 0 0 8px;
    margin: 24px 0 16px;
    width: 100%;
    height: 600px;


    .shop-skeleton-header {
      position: relative;
      width: 100%;
      height: 368px;


      .shop-avatar-skeleton {
        position: absolute;
        bottom: 0;
        left: 24px;

        .ant-skeleton-avatar {
          background: colors.$grey10;
          height: 106px;
          width: 106px;
        }
      }

      .shop-cover-skeleton {
        width: 100%;
        height: 315px;

        .ant-skeleton-header {
          padding-right: 0;

          .ant-skeleton-avatar-square {
            width: 100%;
            height: 100%;
            border-radius: 4px 4px 0 0;

          }
        }
      }
    }

    .shop-skeleton-content {
      margin: 0 24px;
      border-bottom: 1px solid colors.$gray10;
      padding-bottom: 24px;

      .skeleton-shop-title {
        margin-top: 14px;
        width: 130px;

        .ant-skeleton-title {
          height: 24px;
          border-radius: 12px;
          margin: 0;

        }
      }

      .skeleton-shop-subtitle, .skeleton-shop-rate, .skeleton-shop-countries, .skeleton-shop-followers {
        margin-top: 12px;
        width: 200px;

        &.skeleton-shop-rate {
          width: 90px;
        }

        &.skeleton-shop-countries {
          width: 240px;
        }

        &.skeleton-shop-followers {
          width: 200px;
        }

        .ant-skeleton-title {
          height: 20px;
          border-radius: 10px;
          margin: 0;
        }
      }

      .shop-buttons-skeleton {
        display: flex;
        gap: 8px;
        margin-top: 16px;

        .shop-button-skeleton {
          height: 40px;
          width: 160px;

          .ant-skeleton-avatar-square {
            width: 100%;
            height: 100%;
            border-radius: 4px;
          }
        }
      }
    }
  }

  .shop-skeleton-about {
    padding: 24px 24px 16px;

    .about-skeleton-title {
      width: 100px;
    }

    .about-skeleton-subtitle {
      width: 200px;
      padding: 16px 0 4px;

      .ant-skeleton-title {
        height: 24px;
      }
    }
    .about-skeleton-text {
      width: 500px;

      .ant-skeleton-title {
        height: 16px;
      }
    }
  }
}

@media only screen and (max-width: variables.$mobile) {
  .shop-skeleton-container {
    border-radius: 0;

    .shop-skeleton-top-container {
      margin: 12px 0 16px;
      height: 430px;


      .shop-skeleton-header {
        position: relative;
        width: 100%;
        height: 180px;

        .shop-avatar-skeleton {
          position: absolute;
          bottom: 0;
          left: 24px;

          .ant-skeleton-avatar {
            background: colors.$grey10;
            height: 80px;
            width: 80px;
          }
        }

        .shop-cover-skeleton {
          height: 140px;

          .ant-skeleton-header .ant-skeleton-avatar-square {
            border-radius: 0;
          }
        }
      }

      .shop-skeleton-content {
        margin: 0 12px;
        border-bottom: 1px solid colors.$gray10;
        padding: 12px 0 8px;

        .skeleton-shop-title {
          margin-top: 0;
          width: 130px;

          .ant-skeleton-title {
            height: 24px;
            border-radius: 12px;
            margin: 0;

          }
        }

        .skeleton-shop-subtitle, .skeleton-shop-rate, .skeleton-shop-countries, .skeleton-shop-followers {
          margin-top: 12px;
          width: 180px;

          &.skeleton-shop-rate {
            width: 70px;
          }

          &.skeleton-shop-countries {
            width: 220px;
          }

          &.skeleton-shop-followers {
            width: 180px;
          }

          .ant-skeleton-title {
            height: 20px;
            border-radius: 10px;
            margin: 0;
          }
        }

        .shop-buttons-skeleton {
          display: flex;
          gap: 8px;
          margin-top: 16px;

          .shop-button-skeleton {
            height: 40px;
            width: 120px;

            .ant-skeleton-avatar-square {
              width: 100%;
              height: 100%;
              border-radius: 4px;
            }
          }
        }
      }
    }

    .shop-skeleton-about {
      padding: 24px 24px 16px;

      .about-skeleton-title {
        width: 100px;
      }

      .about-skeleton-subtitle {
        width: 200px;
        padding: 16px 0 4px;

        .ant-skeleton-title {
          height: 24px;
        }
      }
      .about-skeleton-text {
        width: 500px;

        .ant-skeleton-title {
          height: 16px;
        }
      }
    }
  }

}