@use "src/styles/mixin";
@use "src/styles/colors";
@use "src/styles/variables";
@use "src/styles/typography";.menu-buttons {
  @include mixin.flexible(row, center, center);
  flex: 1;
  height: 100%;
}


@media only screen and (max-width: variables.$mobile) {
  .menu-buttons {
    justify-content: space-between;
  }
}