@use "src/styles/mixin";
@use "src/styles/colors";
@use "src/styles/variables";

@keyframes slideFromLeftToRight {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(690px);
  }
}

@keyframes progress {
  0% {
    width: 0;
  }
  100% {
  width: 100%;
  }
}

.posts-container {
  @include mixin.flexible(column);
  height: 100%;
  width: 100%;
  margin-top: 1px;

  .loader-container {
    @include mixin.flexible(row, center, center);
    @include mixin.size(100%, 48px);
    padding: 22px 12px;
    background: white;

    .loader {
      position: relative;
      background: colors.$grey10;
      @include mixin.size(100%, 4px);

      .progress {
        @include mixin.size(10px, 4px);
        background-color: colors.$main35;
        animation: progress 1s ease-in-out;
      }

      .image-wrapper {
        @include mixin.size(24px);
        position: absolute;
        top: -10px;
        animation: slideFromLeftToRight 1s ease-in-out;
      }
    }
  }

  .posts-list-container {
    height: 100%;
    .non-sign-in-container {
      background: linear-gradient(180deg, rgba(246, 247, 247, 0.00) -10%, #F6F7F7 70%);
      @include mixin.flexible(column, center, center);
      position: relative;
      bottom: 70%;
      padding-bottom: 48px;
      padding-top: 88px;
    }

    .empty-state {
      margin: 24px 0;
    }
  }

  &.with-create-post {
    .posts-list-container {
      flex: 1;
    }
  }
}
