@use "src/styles/mixin";
@use "src/styles/colors";
@use "src/styles/typography";
@use "src/styles/variables";
.search-container-menu {
  padding: 0 10px;
  display: flex;

  .auto-complete-container {
    cursor: unset;
    height: unset;

    .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
      border-color: colors.$grey10;
    }

    .ant-input-affix-wrapper {
      @extend %body-small;
      height: 40px;
      border-radius: 32px;
      background: colors.$grey5;
      border: none;

      input {
        @extend %body-small;
        color: colors.$darkGrey;
        background: transparent;

        &::placeholder {
          color: colors.$grey50

        }
      }

    }

    .ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
      outline: none;
      box-shadow: none;
    }

    .ant-select-selection-search {
      .ant-input-prefix svg {
        @include mixin.size(16px);

        path {
          fill: colors.$grey50;
        }
      }
    }
  }
}

.search-container-menu:has(.search-container) {
  width: 100%;

  .auto-complete-container {
    width: 100%;
    padding: 0;
  }
}

.auto-complete-dropdown {
  width: 300px;
  padding: 8px;

  .ant-select-selector {
    min-height: unset;
  }

  &.recent {
    .ant-select-item-option-content {
      color: purple;
    }
  }
}

@media only screen and (max-width: variables.$mobile) {
  .search-container-menu {
    width: 100%;

    .auto-complete-container {
      width: 100%;

      .ant-input-affix-wrapper {
        height: 34px;
      }
    }
  }
}
