@use "src/styles/mixin";
@use "src/styles/colors";
@use "src/styles/typography";
@use "src/styles/variables";

.google-sign-in-container {
  @include mixin.size(variables.$max, variables.$fit);
  margin-bottom: 16px;
  border-radius: 32px;

  .google-sign-in {
    @include mixin.size(variables.$max, 48px);
    @include mixin.flexible(row, center, center);
    border-radius: 32px !important;
    box-shadow: none !important;
    border: 1px solid colors.$grey15 !important;
    font-family: "typography.DM-Sans-Medium", serif !important;
    font-size: 16px !important;
    line-height: 1.25rem !important;
    color: colors.$darkGrey !important;

    &:hover:enabled {
      background-color: colors.$grey5 !important;

      > div {
        background-color: colors.$grey5 !important;
      }
    }

    > button > span {
      color: colors.$darkGrey !important;
    }

    > div {
      @include mixin.flexible(row, center, center);
      margin-right: 0 !important;
      padding: 8px !important;
    }
  }

}

