@use "src/styles/mixin";
@use "src/styles/colors";
@use "src/styles/typography";
@use "src/styles/variables";
.follow-button-container {
  @include mixin.flexible(row, center, center);
  width: 100%;

  .follow-button {
    @include mixin.flexible(row, center, center);
    @extend %body-small;
    color:  colors.$themeBlue;
    cursor: pointer;
    border-radius: 2px;
    padding: 2px 0 2px 8px;

    &:hover {
      text-decoration: underline;
    }
  }
}