@use "src/styles/mixin";
@use "src/styles/colors";
@use "src/styles/typography";
@use "src/styles/variables";

#feed-container {
  gap: 32px;
  height: 100%;

  .info-container {
    margin-bottom: 24px;
  }

  .preview {
    padding-left: 0;
    @include mixin.flexible(column);
  }
}