@use "src/styles/mixin";
@use "src/styles/colors";
@use "src/styles/typography";
@use "src/styles/variables";
.reaction-container {
  @include mixin.flexible(row, flex-start, center);
  @extend %body-small;
  gap: 16px;
  color: colors.$grey65;

  .reaction {
    @include mixin.flexible(row, flex-start, center);
    gap: 4px;
    cursor: pointer;

    .number {
      @include typography.button-small;
      color: colors.$darkGrey;
      white-space: pre-wrap;
    }

    &:hover {
      text-decoration: underline;
    }
  }

}