@use "src/styles/mixin";
@use "src/styles/typography";
@use "src/styles/variables";
@use "src/styles/colors";

.one-time-payment-page {
  .ant-skeleton .ant-skeleton-header {
    padding-inline-end: 0;
  }

  .avatar-skeleton {
    width: fit-content;
    margin-top: 8px;
    margin-bottom: 12px;
  }

  .skeleton-title {
    width: 62px;
    height: 24px;
  }

  .skeleton-subtitle {
    margin-top: 2px;
    height: 24px;
    width: 126px;
  }

  .skeleton-price {
    margin-top: 24px;
    height: 66px;
    width: 104px;


    .ant-skeleton-title {
      @include mixin.size(100%);
    }
  }

  .ant-skeleton-title {
    height: 100%;
    margin-bottom: 0;
  }

  .skeleton-input {
    height: 42px;
    margin: 16px 24px 0;
    width: 100%;

    .ant-skeleton-avatar-square {
      @include mixin.size(100%);
      border-radius: 4px;
    }

    .ant-skeleton-content {
      .ant-skeleton-title {
        height: 100%;
        margin-bottom: 0;
      }
    }
  }
}

@media only screen and (max-width: variables.$mobile) {
  .one-time-payment-page {
    .avatar-skeleton {
      margin-top: -58px;
      margin-bottom: 12px;
    }

  }

}