@use "src/styles/mixin";
@use "src/styles/colors";
@use "src/styles/typography";
@use "src/styles/variables";
.sign-in-form {
  margin-top: 24px;
  width: 100%;

  .button {
    border-radius: 32px;
    width: 100%;
  }
}

