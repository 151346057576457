@use "src/styles/mixin";
@use "src/styles/colors";
@use "src/styles/typography";
@use "src/styles/variables";
.notification-row-container {
  @include mixin.flexible(row, space-between);
  width: 100%;
  overflow: hidden;
  cursor: pointer;
  padding: 12px 0 16px 24px;

  &:has(.grid-container) {
    flex-direction: column;
    gap: 16px;
  }

  .left-side {
    display: flex;
    align-items: center;
    gap: 8px;

    .dot {
      @include mixin.size(8px);
      background: colors.$indigo;
      border-radius: 32px;
    }

    .text-container {
      @include mixin.flexible(column);
      margin-left: 4px;

      .title {
        @include mixin.flexible(row, flex-start, center);
        gap: 4px;
      }

      .description {
        display: flex;
        gap: 4px;

        .text {
          @include mixin.ellipsisLines(2, 1.25rem);
          max-width: 250px;
        }

        .date {
          @include typography.body-small;
          color: colors.$grey50;
        }
      }

      .button {
        margin-top: 12px;
      }

    }
  }

  .grid-wrapper {
    width: 100%;
    overflow-x: auto;

    .grid-container {
      display: flex;
      gap: 4px;
      width: fit-content;

      .image-wrapper {
        @include mixin.size(100px);

        .overlay {
          border-radius: 4px;
        }

        img {
          object-fit: cover;
          border-radius: 4px;
        }
      }
    }
  }

  .image-container {
    position: relative;

    .overlay {
      background: none;
    }

    img {
      object-fit: cover;
    }

    &.single {
      @include mixin.size(52px);

      .image-wrapper {
        img {
          border-radius: 4px;
        }
      }
    }

    &.two-diagonal {
      @include mixin.size(52px);

      .image-wrapper:first-child {
        top: 4px;
        right: 0;
        z-index: 1;
      }

      .image-wrapper:last-child {
        bottom: 0;
        left: 0;
      }

      .image-wrapper {
        @include mixin.size(37px);
        position: absolute;
        border: 1px solid white;
        border-radius: 50%;

        img {
          border-radius: 50%;
        }
      }
    }

    &.big-and-small {
      @include mixin.size(52px);

      .image-wrapper:first-child {
        img {
          border-radius: 4px;
        }
      }

      .image-wrapper:last-child {
        @include mixin.size(28px);
        position: absolute;
        bottom: 0;
        right: -8px;

        img {
          border-radius: 50%;
          border: 2px solid white;
        }
      }
    }

    &.image-with-badge {
      @include mixin.size(52px);

      .image-wrapper:first-child {
        img {
          border-radius: 50%;
        }
      }

      .image-wrapper:last-child {
        @include mixin.size(20px);
        position: absolute;
        top: -4px;
        right: 0;

        img {
          border-radius: 50%;
        }
      }
    }
  }

  > .image-container {
    margin-right: 16px;

    .overlay {
      background: getColor(colors.$darkGrey, 0.1);
    }
  }

  &:hover {
    background: colors.$grey5;
  }

  &.new {
    background: colors.$indigo5;
    padding-left: 8px;

    &:hover {
      background: colors.$indigo10;
    }
  }
}