@use "src/styles/mixin";
@use "src/styles/colors";
@use "src/styles/typography";
@use "src/styles/variables";
@use "src/styles/functions";

.vision-seller-badge-container {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 8px;
  
  @include mixin.size(117px, 33px);
  border-radius: 4px;
  max-width:117px;

  background: linear-gradient(160deg, #1ECBC6, #5772FF, #8D57FF);
  background-size: 150% 150%; /* Enlarges the gradient */
  background-position: center; /* Keeps the center stretched */

  .icon {
    @include mixin.size(15px);
    width: 14px;
    path {
      fill: colors.$white;
    }
  }

  .text {
    @include typography.DM-Sans-regular;
    font-size: 14px;
    color: colors.$white;
  }
  
  &.small {
    @include mixin.size(117px, 22px);
    padding: 0 6px;
  }
  
  &.medium {
    @include mixin.size(117px, 28px);
  }
}