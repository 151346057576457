@use "src/styles/mixin";
@use "src/styles/colors";
@use "src/styles/typography";
@use "src/styles/variables";
.page-container {
  > .content {
    > .page {
      .shops-section {
        @include mixin.flexible(column);

        .title {
          padding-top: 16px;
          padding-left: 16px;
        }

        .section-content {
          @include mixin.flexible(column);
          padding: 16px 12px 24px;
          gap: 16px;

          .shop-item {
            transition: transform 0.5s;
            &:hover {
              transform: translateY(-3%);
            }
          }
        }
      }
    }
  }
}