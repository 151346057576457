@use "src/styles/mixin";
@use "src/styles/typography";
@use "src/styles/colors";
@use "src/styles/variables";

.interests-empty-state-container {
  @include mixin.flexible(column, center, center);
  flex: 1;
  gap: 16px;

  .image-wrapper {
    @include mixin.size(70px)
  }

  .text-container {
    @include mixin.flexible(column, center, center);
    padding: 0;

    .title {
      @include typography.heading-5;
      font-size: 16px;
      color: colors.$darkGray;
    }

    .description {
      @include typography.DM-Sans-regular;
      font-size: 14px;
      color: colors.$grey50;
    }
  }
}