@use "src/styles/mixin";
@use "src/styles/typography";
@use "src/styles/colors";
@use "src/styles/variables";

.follow-shops-container {
  background-color: white;
  @include mixin.flexible(column, flex-start, center);
  box-shadow: 0 4px 7px rgba(72, 78, 76, 0.07);
  border-radius: 4px;
  width: 480px;
  height: 656px;

  .text-container {
    width: 100%;
    padding: 40px 56px 0 56px;
    @include mixin.flexible(column);

    .title {
      @include typography.heading-3;
      color: colors.$darkGrey;
      margin-bottom: 4px;
    }

    .description {
      color: colors.$grey50;
      @include typography.body-small;
      margin-bottom: 24px;
    }
  }

  .content-container {
    width: 100%;
    flex:1;
    display: flex;
    flex-direction: column;
    padding: 0 56px 32px 56px;
    gap: 16px;
    overflow: auto;
  }

  .buttons-container {
    padding: 0 56px 40px 56px;
    width: 100%;
    box-shadow: 0 -28px 10px white;
    z-index: 1;

    .continue-button-interests {
      margin-bottom: 16px;
    }
  }
}

@media only screen and (max-width: 900px) {
  .follow-shops-container {
    .text-container {
      width: 100%;
      padding: 0;
    }

    .content-container {
      padding: 0 0 32px 0;
    }

    .buttons-container {
      padding: 0 ;
    }
  }

}