@use "src/styles/mixin";
@use "src/styles/typography";
@use "src/styles/colors";
@use "src/styles/variables";

.follow-shops-skeleton {
  border-radius: 4px;
  position: relative;
  width: 100%;

  .images-container {
    .image-skeleton {
      height: 111px;

      .ant-skeleton-header {
        padding: 0;

        .ant-skeleton-avatar {
          @include mixin.size(100%);
        }
      }
    }
  }

  .avatar-skeleton {
    position: absolute;

    left: 16px;
    top:86px;

    .ant-skeleton-avatar {
      border: 1px white solid;
    }
  }

  .bottom-section {
    @include mixin.flexible(row, space-between, center);
    padding: 27px 16px;
    border-radius: 0 0 4px 4px;
    gap: 12px;
    background-color: colors.$grey2;
    width: 100%;

    .ant-skeleton-paragraph {
      margin: 0;
      width: 143px;
    }

    .ant-skeleton.ant-skeleton-with-avatar.ant-skeleton-active.ant-skeleton-round {
      width: fit-content;

      .ant-skeleton-header {
        padding-right: 0;
      }
    }
  }
}