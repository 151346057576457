@use "src/styles/mixin";
@use "src/styles/colors";
@use "src/styles/typography";
@use "src/styles/variables";
.sticky-layout-container {
  width: 100%;
  display: flex;
  gap: 24px;

  .list {
    position: sticky;
    position: -webkit-sticky;
    height: fit-content;
    overflow-x: hidden;
    overflow-y: initial;
  }

  .preview {
    height: 100%;
  }
}

@media only screen and (max-width: 900px) {
  .sticky-layout-container {
    justify-content: center;
  }
}