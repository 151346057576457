@use "src/styles/mixin";
@use "src/styles/colors";
@use "src/styles/variables";
@use "src/styles/typography";

.footer-menu {
  @include mixin.size(100%, variables.$menuSizeFooter);
  border-top: 1px solid colors.$grey10;

  &.is-non-sign-in-user {
    @include mixin.size(100%,60px);
  }

  &.dark-theme {
    background: getColor(#484E4C99, 1);
    box-shadow: 0 7px 7px 0 rgba(109, 109, 109, 0.04);

    .search-container-menu {
      .ant-input-affix-wrapper {
        border: 1px solid colors.$grey50;
        background: colors.$grey85;

        .ant-input-prefix {
          svg path {
            fill: white;
          }
        }

        input {
          color: white;


          &::placeholder {
            color: white;
          }
        }
      }
    }

    .menu-item {

      svg path {
        fill: colors.$grey50;
      }

      .line {
        background: none;
      }

      &.selected {
        svg path {
          fill: white;
        }

        .line {
          background: white;
        }
      }

      &:hover {
        background: colors.$grey65;
      }
    }

    .circle-button {
      background: colors.$grey85;
      border-color: colors.$grey65;

      svg path {
        fill: white;
      }

      &:hover {
        background: colors.$grey65;
      }
    }

    .profile-section {
      .menu-drop-button {
        svg path {
          fill: white;
        }

        &:hover {
          background: colors.$grey65;
        }
      }
    }
  }
}
