@use "src/styles/mixin";
@use "src/styles/typography";
@use "src/styles/colors";
@use "src/styles/variables";

.interests-container {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 4px 7px rgba(72, 78, 76, 0.07);
  width: 480px;
  height: 656px;
  @include mixin.flexible(column, flex-start, center);
  position: relative;

  .text-container {
    @include mixin.flexible(column);
    width: 100%;
    padding: 40px 56px 26px 56px;

    .title {
      @include typography.heading-3;
      color: colors.$darkGrey;
      margin-bottom: 4px;
    }

    .description {
      color: colors.$grey50;
      @include typography.body-small;
    }
  }

  .search-input-container {
    width: 100%;
    padding: 0 56px 10px 56px;

    .ant-input-affix-wrapper {
      border-radius: 32px;
    }

    .search-icon {
      @include mixin.size(16px);

      path {
        fill: colors.$grey50;
      }
    }
  }

  .buttons-container {
    width: 100%;
    padding: 0 56px 48px 56px;
    box-shadow: 0 -28px 10px white;

    .continue-button-interests {
      margin-bottom: 16px;
    }
  }
}

@media only screen and (max-width: 900px) {
  .interests-container {
    box-shadow: none;
    height: 100%;
    width: 100%;
    position: relative;

    .search-input-container {
        padding: 0 0 10px 0;
    }

    .text-container {
      padding: 0 0 24px 0;
    }

    .interests-category-content-container {
      flex:1;
      padding: 0;
    }

    .buttons-container {
      padding: 0;
      box-shadow: 0 -28px 10px white;
    }
  }
}
