@use "src/styles/mixin";
@use "src/styles/typography";
@use "src/styles/colors";
@use "src/styles/variables";

.like-button-container {
  @include mixin.size(32px);
  @include mixin.flexible(row, center, center);
  position: absolute;
  bottom: 12px;
  right: 12px;
  z-index: 1;
  background: colors.$grey5;
  border-radius: 32px;
  cursor: pointer;

  svg {
    @include mixin.size(18px);

    &.filled-heart {
      path {
        fill: colors.$main;
      }
    }
  }
}