@use "src/styles/mixin";
@use "src/styles/colors";
@use "src/styles/typography";
@use "src/styles/variables";

.logged-out-menu {
  @include mixin.flexible(row, center, center);
  @include mixin.size(variables.$max, variables.$menuSizeHeader);
  width: 100%;
  z-index: 2;
  border-bottom: 1px solid getColor(black, 0.08);

  .inner-content {
    @include mixin.flexible(row, space-between, center);
    @include mixin.size(variables.$max);
    max-width: 1120px;

    .tedooo-logo {
      @include mixin.size(40px);
      @include mixin.flexible(row, center, center);
      border-radius: 4px;
      cursor: pointer;

      svg {
        @include mixin.size(28px)
      }
    }
    .logged-out-menu-buttons-container {
      flex: 1;

      .logged-out-menu-buttons {
        @include mixin.flexible(row)
      }
    }

    .buttons {
      @include mixin.flexible(row);

      .button {
        margin-right: 16px;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: variables.$mobile) {
  .logged-out-menu {
    .inner-content {
      padding: 0 8px;


      .tedooo-logo {
        @include mixin.size(60px);

        border-radius: 4px;
        cursor: pointer;

        svg {
          @include mixin.size(40px)
        }
      }

      .buttons {
        @include mixin.flexible(row);

        .button {
          white-space: nowrap;
          padding: 0 8px;

          .text {
            margin: 4px;
          }

          &:first-child {
            display: none;
          }
        }
      }
    }
  }
}