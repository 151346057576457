@use "src/styles/mixin";
@use "src/styles/colors";
@use "src/styles/typography";
@use "src/styles/variables";
.notifications-popover {
.ant-popover-arrow {
  margin-right: -6px;
}

  .notifications-container {
    @include mixin.flexible(column);
    width: 472px;
    padding-top: 16px;
    overflow-y: auto;
    height: 697px;

    .title-container {
      @include mixin.flexible(row, flex-start, center);
      gap: 8px;
      padding-left: 16px;

      .clickable-image {
        @include mixin.size(32px);
        display: none;
      }

      .title {
        @include typography.heading-5;
        color: colors.$darkGrey;
      }
    }

    .ant-tabs {
      margin-bottom: 12px;

      .ant-tabs-nav-list {
        .ant-tabs-tab {
          @include typography.body-small;
          padding: 16px;
        }
      }
    }

    .sub-title {
      @include typography.sub-heading;
      color: colors.$grey50;
      text-transform: uppercase;
      padding: 0 16px 16px;
    }
  }

  .notification-skeleton {
    padding: 16px;

    .ant-skeleton-title {
      margin: 0;
    }

    .ant-skeleton-with-avatar {
      .ant-skeleton-content {
        .ant-skeleton-paragraph {
          margin: 0;
        }
      }
    }
  }
}


@media only screen and (max-width: 900px) {
  .notifications-popover {
    padding: 0;

    .notifications-container {
      @include mixin.size(100vw, 100vh);

      .title-container {
        .clickable-image {
          display: unset;
        }
      }
    }
  }
}
