@use "src/styles/mixin";
@use "src/styles/colors";
@use "src/styles/typography";
@use "src/styles/variables";
.profile-menu-button {
  @include mixin.flexible(column, flex-start, center);
  max-width: 90px;
  min-width: 80px;
  cursor: pointer;

  > img {
    margin-bottom: 2px;
  }

  .text-container {
    @include mixin.flexible(row, center, center);

    .text {
      @extend %body-small;
      text-align: center;
      color: getColor(black, 0.6);
    }

    svg {
      @include mixin.size(10px, 5px);
      transform: rotate(180deg);
      margin-left: 4px;

      path {
        fill: getColor(black, 0.6);
      }
    }
  }
}
