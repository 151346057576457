@use "src/styles/mixin";

@use "src/styles/colors";
@use "src/styles/typography";
@use "src/styles/variables";

.profile-side-bar {
  @include mixin.flexible(column);
  @include mixin.size(variables.$max, variables.$fit);

  .complete-shop-box-container {
    margin-top: 24px;
  }
}