@use "src/styles/mixin";
@use "src/styles/variables";
@use "src/styles/colors";
@use "src/styles/typography";
.unread-container {
  @include mixin.flexible(row, center, center);
  height: 18px;
  background-color: colors.$main;
  border-radius: 9px;
  color: white;
  text-align: center;
  min-width: 18px;

  span {
    @include typography.label-small;
    padding: 4px;
  }
}
