@use "src/styles/mixin";
@use "src/styles/colors";
@use "src/styles/typography";
@use "src/styles/variables";
@use "src/styles/functions";

.premium-tag-container {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0 12px;
  @include mixin.size(77px, 33px);
  background-color: functions.getColor(#191E1C, 0.8);
  border-radius: 4px;

  .icon {
    @include mixin.size(17px);
  }

  .text {
    @include typography.poppins-regular;
    font-size: 0.875rem;
    color: white;
  }

  &.medium {
    @include mixin.size(59px, 27px);
    padding: 0 8px;
    gap: 4px;

    .icon {
      @include mixin.size(15px);
    }
  }

  &.small {
    @include mixin.size(56px, 22px);
    padding: 0 6px;
    gap: 4px;

    .icon {
      @include mixin.size(15px);
    }

  }
}