@use "src/styles/mixin";
@use "src/styles/colors";
@use "src/styles/typography";
@use "src/styles/variables";
.empty-state {
  @include mixin.flexible(column, center, center);
  @extend %body-small;
  color: colors.$grey50;
  flex: 1;
  padding-bottom: 24px;

  .text-container {
    @include mixin.flexible(column, flex-start, center);
    text-align: center;

    .title {
      @include typography.heading-5;
      color: colors.$darkGrey;
      margin-bottom: 8px;
    }

    .image-wrapper {
      @include mixin.size(120px, 70px);
      margin-bottom: 16px;
    }
  }


  .button {
    margin-top: 16px;
  }

  &.without-image {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 16px;

    .text-container {
      align-items: flex-start;
    }

    .button {
      transform: translateY(-75%);
      margin: 0;
    }
  }
}